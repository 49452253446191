import throttle from "lodash/throttle";
import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    this.stickyFooter = document.querySelector(".sticky-footer");
    this.mobileHeader = document.querySelector(".js-mobile-header");
    this.hamburgerToggle = document.querySelectorAll(".js-hamburger-toggle");
    this.framesLeft = document.querySelectorAll(".frame__sides--left, .frame-wide__sides--left");
    this.navItems = document.querySelectorAll(".js-nav-items a");
    this.hamburgerToggle.forEach((el) => {
      el.addEventListener("click", this.toggleMobileMenu.bind(this));
    });
    this.navItems.forEach((el) => {
      el.addEventListener("click", this.closeDrawer.bind(this));
    });
    window.addEventListener("scroll", throttle(this.pagescroll.bind(this), 250));
    window.addEventListener('load', this.handlePageLoad.bind(this));
  }

  handlePageLoad() {
    const hash = window.location.hash;
    if (hash) {
      this.smoothScrollToAnchor(hash);
    }
  }

  smoothScrollToAnchor(selector) {
    const targetElement = document.querySelector(selector);
    if (targetElement) {
      setTimeout(() => {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }
  
  closeDrawer() {
    this.mobileHeader.classList.remove("is-expanded");
    this.hamburgerToggle.forEach((el) => {
      el.classList.remove("open");
      el.setAttribute("aria-expanded", false);
    });
    document.body.classList.remove("-is-scroll-locked");
  }

  toggleMobileMenu() {
    document.body.classList.toggle("-is-scroll-locked");
    this.mobileHeader.classList.toggle("is-expanded");
    this.framesLeft.forEach((frame) => {
      frame.classList.toggle("-menu-open");
    });
    let isOpen = this.mobileHeader.classList.contains("is-expanded");
    this.hamburgerToggle.forEach((el) => {
      el.classList.toggle("-close-icon");
      el.setAttribute("aria-expanded", isOpen);
    });
  }

  pagescroll() {
    let body = document.body;
    let header = this.element;
    let footer = document.querySelector("footer.site-footer");
    let viewport = {
      top: window.scrollY,
      bot: window.scrollY + window.innerHeight,
    };

    // Page has started scrolling
    if (!['events', 'press', 'stories'].some((name) => body.classList.contains(name))) {
      let isScrolling = viewport.top > 320;
      body.classList.toggle('scroll-header', isScrolling);
      header.classList.toggle('scroll-header', isScrolling);
      this.stickyFooter.classList.toggle('-page-scrolling', isScrolling);
    }

    // Page is at the bottom near the footer
    let nearBottom = viewport.bot > footer.offsetTop;
    body.classList.toggle("-is-bottom", nearBottom);
    body.classList.toggle("-page-bottom", nearBottom);
  }
}
