import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    this.buttonTargets.forEach((el) => {
      el.addEventListener("click", this.toggleReservations.bind(this));
    });
  }

  toggleReservations(event) {
    const clickedElement = event.currentTarget;
    clickedElement.classList.toggle("is-expanded");
    let isOpen = clickedElement.classList.contains("is-expanded");
    clickedElement.setAttribute("aria-expanded", isOpen);
  }
}
